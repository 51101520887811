<template>
  <div class="addRelatedTopic">
    <el-row>
      <el-col :span="10">
        <el-input v-model="filterText" prefix-icon="el-icon-search" placeholder="搜索话题名称">
        </el-input>

        <el-divider />

        <el-collapse v-for="categoryItem in showData" :key="categoryItem.id" :value="['1']">
          <el-collapse-item :title="categoryItem.topicName" name="1">
            <div class="tags">
              <div
                v-for="item in categoryItem.children"
                :key="item.id"
                class="tag"
                @click="tagSelect(item.subjectId)"
              >
                {{ item.subjectName }}
                <img v-show="item.isSelect" class="icon" src="../../../../assets/tagClick.png" />
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-col>
      <el-col :span="14">
        <div style="padding: 0 0 0 30px">
          <div class="selectHeader">已选{{ selectList.length }}/{{ total }}</div>

          <div class="selectTags">
            <el-tag
              v-for="item in selectList"
              :key="item.id"
              class="tag"
              closable
              style="margin-right: 10px; margin-bottom: 10px"
              @close="tagClose(item)"
            >
              {{ item.subjectName }}
            </el-tag>
          </div>

          <div class="bottom-button">
            <el-button size="mini" @click="close">取消</el-button>
            <el-button size="mini" type="primary" @click="submit">确认</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getTopicList } from '@/api/friendCircle.js'

export default {
  props: {
    selectedList: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      filterText: '',
      data: [
        {
          topicName: '话题列表',
          id: 123,
          level: 1,
          children: [],
        },
      ],
      selectList: [],
    }
  },
  computed: {
    total() {
      // return this.data.reduce((left, right) => {
      //   return left.children.length + right.children.length;
      // });
      return this.data[0].children.length
    },

    showData() {
      if (this.filterText) {
        const newArr = JSON.parse(JSON.stringify(this.data))

        const dataArr = newArr.map((el) => {
          // 过滤第一层
          if (el.topicName.includes(this.filterText)) {
            return el
          } else {
            // 过滤第二层
            el.children = el.children.filter((child) => {
              return child.subjectName.includes(this.filterText)
            })
            return el
          }
        })

        return dataArr.filter((el) => el.children.length > 0)
      }

      return this.data
    },
  },
  watch: {},
  async mounted() {
    await this.getList()

    this.selectedList.some((el) => {
      this.tagSelect(el.subjectId)
    })
  },
  methods: {
    filterNode(value, data) {
      if (data.tagList && data.tagList.filter((i) => i.name.indexOf(value) !== -1).length > 0) {
        return true
      }

      if (data.name.indexOf(value) !== -1) {
        return true
      }
      if (!value) return true
      return false
      //   return data.label.indexOf(value) !== -1;
    },

    tagSelect(id) {
      this.data.some((el) => {
        el.children.some((tagItem) => {
          if (tagItem.subjectId === id) {
            tagItem.isSelect = !tagItem.isSelect

            if (this.selectList.map((el3) => el3.subjectId).includes(id)) {
              this.selectList.splice(
                this.selectList.findIndex((el4) => el4.subjectId === id),
                1,
              )
            } else {
              this.selectList.push(tagItem)
            }

            return true
          }
        })
      })
    },

    tagClose(tag) {
      this.selectList.splice(
        this.selectList.findIndex((el) => el.subjectId === tag.subjectId),
        1,
      )

      const parentIndex = this.data.findIndex((el) => el.id === tag.parentId)

      this.data[parentIndex].children.forEach((el) => {
        if (el.subjectId === tag.subjectId) {
          el.isSelect = !el.isSelect
        }
      })
    },

    close() {
      this.$emit('close')
    },

    submit() {
      if (this.selectList.length === 0) {
        this.$message.error('请选择关联话题')
        return
      }

      this.$emit('ok', this.selectList)
    },

    async getList() {
      const reqObj = {
        data: {
          searchKey: '',
        },
        pageSize: 500,
      }

      const res = await getTopicList(reqObj)

      if (res.success) {
        res.data.content.forEach((el) => {
          el.isSelect = false
          el.parentId = 123
        })
        this.data[0].children = res.data.content
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.addRelatedTopic {
  .tags {
    // .tagitems::after {
    //   content: "";
    //   clear: both;
    // }
    display: flex;
    flex-direction: column;

    .tag {
      padding: 0px 16px;
      height: 30px;
      border-radius: 4px;
      border: 1px solid #e0e0e0;
      text-align: center;
      line-height: 30px;
      position: relative;
      float: left;
      margin: 4px 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .icon {
        position: absolute;
        right: 0px;
        bottom: 0;
      }
    }

    .tag:hover {
      cursor: pointer;
    }

    .tag:focus {
      background-color: #f5f7fa;
    }

    .tag-isSelect {
      border: 1px solid #2861e2;
    }
  }

  ::v-deep .el-collapse-item__wrap {
    border-bottom: unset;
  }

  ::v-deep .el-collapse {
    border-bottom: unset;
    border-top: unset;
  }

  ::v-deep .el-collapse-item__header {
    height: 20px;
    border-bottom: unset;
  }

  .selectTags {
    height: 343px;
    overflow-y: auto;
  }

  .bottom-button {
    text-align: right;
    margin-top: 15px;
  }
}
</style>
