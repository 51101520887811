<template>
  <div class="momentsCategoryList">
    <div class="search-form">
      <el-form inline>
        <el-form-item label="分类名称">
          <el-input v-model="searchForm.searchKey" size="mini" placeholder="分类ID/名称"></el-input>
        </el-form-item>

        <el-form-item label="分类状态">
          <el-select v-model="searchForm.isDisable" size="mini" placeholder="请选择分类状态">
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button size="mini" type="primary" @click="search">查询</el-button>
          <el-button size="mini" icon="el-icon-refresh-left" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>

      <div>
        <el-button
          style="margin-top: 6px"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="addOrEdit(1)"
          >新增</el-button
        >
      </div>
    </div>

    <div class="category-table">
      <el-table border style="width: 100%" :data="tableData">
        <el-table-column width="60" type="index" label="序号"></el-table-column>
        <el-table-column width="116" label="分类ID" prop="topicCategoryCode"></el-table-column>
        <el-table-column width="100" label="分类名称" prop="topicCategoryName"></el-table-column>
        <el-table-column width="100" label="最后修改人" prop="updateUserName"></el-table-column>
        <el-table-column width="124" label="最后修改时间" prop="updateTime"></el-table-column>
        <el-table-column width="88" label="动态数量" prop="topicNum"></el-table-column>
        <el-table-column width="88" label="浏览次数" prop="browseNum"></el-table-column>
        <el-table-column width="74" label="点赞数" prop="goodsNum"></el-table-column>
        <el-table-column width="74" label="分享数" prop="shareNum"></el-table-column>
        <el-table-column label="关联话题">
          <template slot-scope="{ row }">
            <span v-for="item in row.subjectList" :key="item.subjectId" style="margin-right: 10px"
              >#{{ item.subjectName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column width="121" label="分类状态" prop="state">
          <template slot-scope="{ row }">
            <span v-if="row.isDisable == 0" style="color: #00a199">已启用</span>
            <span v-if="row.isDisable == 1" style="color: #9a9d9e">已禁用</span>
          </template>
        </el-table-column>
        <el-table-column width="200" label="操作">
          <template slot-scope="{ row }">
            <div style="color: #2861e2; cursor: pointer">
              <span v-if="row.isDisable == 1" class="text-button" @click="disable(row)">启用</span>
              <span
                v-if="row.isDisable == 0"
                style="color: #e86247"
                class="text-button"
                @click="disable(row)"
                >禁用</span
              >

              <span class="text-button-last" @click="addOrEdit(0, row)">编辑</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination-container">
      <el-pagination
        :current-page="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="currentPageSize"
        layout=" prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      v-if="dialogVisible"
      class="addDialog"
      :title="`${isEdit ? '编辑分类' : '新增分类'}`"
      :visible.sync="dialogVisible"
    >
      <add-new-category
        :edit-record.sync="editRecord"
        :list-length="listTotal"
        @close="dialogClose"
      >
      </add-new-category>
    </el-dialog>
  </div>
</template>

<script>
import addNewCategory from './compoents/addNewCategory.vue'
import { getMomentsCategoryList, momentsCategoryDisable } from '@/api/friendCircle.js'

export default {
  components: {
    addNewCategory,
  },
  data() {
    return {
      searchForm: {
        searchKey: '',
        isDisable: '',
      },
      options: [
        { value: 0, label: '启用' },
        { value: 1, label: '禁用' },
      ],
      tableData: [],
      currentPageSize: 10,
      currentPage: 1,
      dialogVisible: false,
      isEdit: false,
      editRecord: {},
      total: 0,
      listTotal: 0,
    }
  },
  mounted() {
    this.getList()
    this.getListTotal()
  },
  methods: {
    handleSizeChange(val) {
      this.currentPageSize = val
      this.getList()
    },

    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    },

    async addOrEdit(type, row) {
      await this.getListTotal()
      this.dialogVisible = true

      // 新增
      if (type) {
      }
      // 编辑
      else {
        this.isEdit = true
        this.editRecord = row
      }
    },

    dialogClose() {
      this.dialogVisible = false
      this.isEdit = false
      this.getList()
    },

    getList() {
      const reqObj = {
        data: {
          ...this.searchForm,
        },
        pageNumber: this.currentPage,
        pageSize: this.currentPageSize,
      }

      getMomentsCategoryList(reqObj).then((res) => {
        if (res.success) {
          this.tableData = res.data.content
          this.total = Number(res.data.total)
        }
      })
    },

    search() {
      this.currentPage = 1
      this.getList()
    },

    reset() {
      Object.assign(this._data.searchForm, this.$options.data().searchForm)
      this.currentPage = 1
      this.getList()
    },

    disable(row) {
      const reqObj = {
        data: {
          topicCategoryId: row.topicCategoryId,
          isDisable: row.isDisable === 1 ? 0 : 1,
        },
      }

      momentsCategoryDisable(reqObj)
        .then((res) => {
          if (res.success) {
            this.$message.success('操作成功')
            this.getList()
          }
        })
        .catch((e) => this.$message.console.error(e))
    },

    async getListTotal() {
      const reqObj = {
        data: {
          searchKey: '',
        },
      }

      const res = await getMomentsCategoryList(reqObj)

      if (res.success) {
        this.listTotal = Number(res.data.total)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.momentsCategoryList {
  padding: 24px 20px;
  background: #ffffff;

  .search-form {
    ::v-deep .el-form-item {
      margin-right: 20px !important;
      margin-bottom: 24px;
    }

    display: flex;
    justify-content: space-between;
  }

  .text-button {
    border-right: 1px solid #2861e2;
    padding: 0 5px;

    &-last {
      border-right: unset;
      padding: 0 5px;
    }
  }

  .addDialog {
    ::v-deep .el-dialog__body {
      padding: unset;
    }
  }
}
</style>
