<template>
  <div class="addNewCategory">
    <div style="padding: 40px 78px">
      <el-form ref="form" :model="formData" label-width="120px" class="categoryForm" :rules="rules">
        <el-form-item prop="topicCategoryName" label="分类名称：">
          <el-input
            v-model="formData.topicCategoryName"
            maxlength="4"
            placeholder="请填写分类名称"
            show-word-limit
          >
          </el-input>
          <!-- <div class="tips">最多输入8个字</div> -->
        </el-form-item>

        <el-form-item prop="isDisable" label="分类状态：">
          <el-radio v-model="formData.isDisable" label="0">启用</el-radio>
          <el-radio v-model="formData.isDisable" label="1">禁用</el-radio>
        </el-form-item>

        <el-form-item v-show="formData.isDisable == 0" prop="sort" label="排序：">
          <el-input-number
            v-model="formData.sort"
            :min="1"
            :max="listLength + 1"
            :step="1"
            step-strictly
          ></el-input-number>
          <div class="tips">仅支持≥1的整数</div>
        </el-form-item>

        <el-form-item prop="topicSortType" label="动态排列规则：">
          <el-radio v-model="formData.topicSortType" label="1">按时间</el-radio>
          <el-radio v-model="formData.topicSortType" label="2">按热度</el-radio>
        </el-form-item>

        <el-form-item prop="subjectList" label="关联话题：">
          <el-button style="border: unset" size="mini" type="primary" plain @click="addRelatedTopic"
            >添加关联话题</el-button
          >
          <div class="relatedTopic">
            <el-button
              v-for="item in formData.subjectList"
              :key="item.id"
              style="border: unset; color: #000; margin-left: unset; margin-right: 10px"
              type="info"
              size="mini"
              plain
              >#{{ item.subjectName
              }}<i class="el-icon-close el-icon--right" @click.stop="deleteTopic(item)"></i
            ></el-button>
          </div>

          <!-- {{ formData.subjectList }} -->
        </el-form-item>
      </el-form>
    </div>

    <div class="bottom">
      <el-button size="mini" @click="cancel">取消</el-button>
      <el-button size="mini" type="primary" @click="submit">确定</el-button>
    </div>

    <el-dialog
      v-if="dialogVisible"
      title="添加关联话题"
      append-to-body
      :visible.sync="dialogVisible"
    >
      <add-related-topic :selected-list="formData.subjectList" @close="dialogClose" @ok="dialogOk">
      </add-related-topic>
    </el-dialog>
  </div>
</template>

<script>
import addRelatedTopic from './addRelatedTopic.vue'
import { addMomentsCategory, updateMomentsCategory } from '@/api/friendCircle.js'

export default {
  components: {
    addRelatedTopic,
  },

  props: {
    editRecord: {
      type: Object,
      default: function () {
        return {}
      },
    },

    listLength: {
      type: Number,
      default: 2,
    },
  },

  data() {
    return {
      formData: {
        topicCategoryName: '',
        isDisable: '0',
        sort: '0',
        topicSortType: '1',
        subjectList: [],
      },
      dialogVisible: false,
      rules: {
        topicCategoryName: [{ required: true, message: '请填写分类名称', trigger: 'blur' }],
        isDisable: [{ required: true, message: '请选择分类状态' }],
        sort: [{ required: true, message: '请选择排序顺序' }],
        topicSortType: [{ required: true, message: '请选择动态排列规则' }],
        subjectList: [{ required: true, message: '请添加关联话题' }],
      },
    }
  },

  computed: {
    max() {
      return this.listLength + 1
    },
  },

  async mounted() {
    if (Object.keys(this.editRecord).length === 0) {
      this.formData.sort = Number(this.listLength + 1)
    } else {
      const { topicCategoryName, isDisable, sort, topicSortType, subjectList } = this.editRecord
      this.formData = {
        topicCategoryName,
        isDisable: String(isDisable),
        sort,
        topicSortType: String(topicSortType),
        subjectList,
      }
    }
  },

  beforeDestroy() {
    Object.assign(this._data, this.$options.data())
    this.$emit('update:editRecord', {})
    this.cancel()
  },

  methods: {
    addRelatedTopic() {
      this.dialogVisible = true
    },

    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (Object.keys(this.editRecord).length === 0) {
            const reqObj = {
              data: {
                topicCategoryName: this.formData.topicCategoryName,
                isDisable: this.formData.isDisable,
                sort: this.formData.sort,
                topicSortType: this.formData.topicSortType,
                subjectIdList: this.formData.subjectList.map((el) => el.subjectId),
              },
            }

            addMomentsCategory(reqObj)
              .then((res) => {
                if (res.success) {
                  this.$message.success('保存成功')
                  this.cancel()
                }
              })
              .catch((e) => this.$message.error(e))
          } else {
            const reqObj = {
              data: {
                topicCategoryId: this.editRecord.topicCategoryId,
                topicCategoryName: this.formData.topicCategoryName,
                isDisable: this.formData.isDisable,
                sort: this.formData.sort,
                topicSortType: this.formData.topicSortType,
                subjectIdList: this.formData.subjectList.map((el) => el.subjectId),
              },
            }

            updateMomentsCategory(reqObj)
              .then((res) => {
                if (res.success) {
                  this.$message.success('保存成功')
                  this.cancel()
                }
              })
              .catch((e) => this.$message.error(e))
          }
        }
      })
    },

    cancel() {
      this.$emit('close')
    },

    dialogClose() {
      this.dialogVisible = false
    },

    dialogOk(selectList) {
      this.formData.subjectList = selectList

      this.dialogVisible = false
    },

    deleteTopic(topic) {
      this.formData.subjectList.splice(
        this.formData.subjectList.findIndex((el) => el.subjectId === topic.subjectList),
        1,
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.addNewCategory {
  .categoryForm {
    .tips {
      color: #9a9d9e;
    }
  }

  .bottom {
    padding: 12px 24px;
    text-align: right;
    border-top: 1px solid #ededed;
  }
}
</style>
